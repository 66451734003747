import { render, staticRenderFns } from "./article.vue?vue&type=template&id=6b03d5ec&scoped=true&"
import script from "./article.vue?vue&type=script&lang=js&"
export * from "./article.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./article.vue?vue&type=style&index=1&id=6b03d5ec&prod&scoped=true&lang=css&"
import style2 from "./article.vue?vue&type=style&index=2&id=6b03d5ec&prod&scoped=true&lang=css&"
import style3 from "./article.vue?vue&type=style&index=3&id=6b03d5ec&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b03d5ec",
  null
  
)

export default component.exports